.loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading.large img {
    width: 200px;
}

.loading.middle img {
    width: 80px;
    height: 80px;
}

.loading.yydis img {
    width: 60px;
    height: 60px;
}

.loading.small img {
    width: 20px;
    height: 20px;
}