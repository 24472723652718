.chat-end {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.chat-end h3 {
    font-size: 15px;
    color: #999999;
    margin-bottom: 6px;
}

.chat-end button {
    font-size: 13px;
    border: none;
    background: none;
    color: #00b0ff;
    text-decoration: underline;
    cursor: pointer;
}