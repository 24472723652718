*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  --white: #fff;
  --black: #000;
  --bg: #f8f8f8;
  --grey: #999;
  --dark: #1a1a1a;
  --light: #e6e6e6;
  --wrapper: 1000px;
  --blue: #00b0ff;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: "Open Sans",sans-serif;
  font-weight: 400;
  height: 100vh;
  overflow: hidden;
  background: #f7f7f7;
}

#root {
  height: 100%;
  overflow: hidden;
}

.container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.container .board {
  position: relative;
  flex: 1;
  min-width:640px;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #dbdbdb;
  margin-right: 10px;
  border-right: 1px solid var(--light);
  border-radius: 5px;
}

.container .board .top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 18px 20px;
  width: 100%;
  box-sizing: border-box;
  background: #f2f2f2;
}

.container .board .top img {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  margin-right: 8px;
}

.container .board .top span {
  color: var(--dark);
  font-family: "Open Sans",sans-serif;
  font-weight: 600;
}

.container .board .chat {
  display: flex;
  flex: 1;
  position: relative;
  overflow: hidden;
  padding: 12px 8px 12px;
  width: 100%;
  box-sizing: border-box;
  justify-content: flex-end;
  flex-direction: column;
  overflow: hidden;
}

.container .board .board-loading {
  width: 100%;
  height: 24px;
  padding-bottom: 20px;
}

.container .board .chat-board::-webkit-scrollbar {
  width: 6px;
  height: 1px;
}

.container .board .chat-board::-webkit-scrollbar-thumb {
  border-radius: 6px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}

.container .board .chat-board::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background: #ededed;
}

.container .board .chat-board {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 25px 15px;
  border-bottom: 1px solid #dbdbdb;
}

.container .board .chat.active-chat {
  display: block;
  display: flex;
}

.container .board .end-btn {
  position: absolute;
  left: 50%;
  bottom: 172px;
  width: 80px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #f46212;
  color: #f46212;
  background: #ffffff;
  cursor: pointer;
}

.container .board .write {
  /*position: absolute;*/
  /*bottom: 15px;*/
  /*left: 15px;*/
  height: 140px;
  padding-left: 8px;
  border: 1px solid var(--light);
  background-color: #eceff1;
  width: calc(100% - 30px);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  /*justify-content: space-between;*/
  align-items: flex-start;
  margin-left: 15px;
  margin-bottom: 15px;
}

.container .board .chat-tool{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.container .board .chat-tool-l{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.container .board .chat-tool-r{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.container .board .tool-icon {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  margin: 0 6px;
}

.container .board .tool-icon.icon-crop {
  background-image: url("../img/icon-crop.png");
}

.container .board .tool-icon.icon-file {
  background-image: url("../img/icon-file.png");
}

.container .board .tool-icon.icon-info {
  background-image: url("../img/icon-info.png");
}

.container .board .tool-icon.icon-consult {
  background-image: url("../img/icon-consult.png");
}

.container .board .tool-icon.icon-history {
  background-image: url("../img/icon-history.png");
}

.container .board .write #fileupload {
  display: none;
}

.container .board .write-box::-webkit-scrollbar {
  width: 0 !important;
}

.container .board .write-box {
  -ms-overflow-style: none;
}

.container .board .write-box {
  overflow: -moz-scrollbars-none;
}

.container .board .input-box-all {
  height: 70px;
  width: 100%;
  background-color: #eceff1;
  overflow-y: auto;
}

.container .board .write-box{
  flex: 1;
  font-size: 14px;
  box-sizing: border-box;
  padding: 6px;
  color: var(--dark);
  border: 0;
  outline: none;
  font-family: "Open Sans",sans-serif;
  font-weight: 400;
  line-height: 24px;
}

.container .board .write .write-link {
  cursor: pointer;
  /*position: absolute;*/
  align-self: end;
  margin-right: 12px;
}

.container .board .write .write-link.attach {
  right: 36px;
}

.container .board .write .write-link.send {
  right: 12px;
}

.container .board .write .write-link.attach:before {
  display: inline-block;
  width: 20px;
  height: 42px;
  content: '';
  background-image: url("../img/attachment.png");
  background-repeat: no-repeat;
  background-position: center;
}

.container .board .write .write-link.smiley:before {
  display: inline-block;
  width: 20px;
  height: 42px;
  content: '';
  background-image: url("../img/smiley.png");
  background-repeat: no-repeat;
  background-position: center;
}

.container .board .write .write-link.send:before {
  display: inline-block;
  width: 20px;
  height: 42px;
  margin-left: 11px;
  content: '';
  background-image: url("../img/send.png");
  background-repeat: no-repeat;
  background-position: center;
}

.container .board .chat-msg {
  display: flex;
  margin-bottom: 16px;
}

.container .board .chat-avatar {
  width: 42px;
  height: 42px;
  border-radius: 21px;
}

.container .board .chat-msg.start {
  justify-content: flex-start;
}

.container .board .chat-msg.end {
  flex-flow: row-reverse;
}

.container .board .msg-align {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 16px;
}

.container .board .chat-msg.start .msg-align {
  align-items: flex-start;
}

.container .board .chat-msg.end .msg-align {
  align-items: flex-end;
}

.container .board .chat-msg.start .msg-unification {
  display: flex;
  flex-direction: row;
}

.container .board .chat-msg.end .msg-unification {
  display: flex;
  flex-direction: row-reverse;
}

.container .board .msg-setCol {
  display: flex;
  align-items: flex-end;
}

.container .board .msg-img {
  padding: 0 10px;
  vertical-align: middle;
}

.container .board .msg-iconfont {
  display: flex;
  align-items: flex-end;
  visibility: hidden;
  cursor: pointer;
  position: relative;
  margin: 0 5px;
  /*margin-top: 162px;*/
  padding: 2px 15px 5px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 223, 223);
  border-radius: 13px;
}

/*.container .board .msg-iconfont img{*/
/*  width: 20px;*/
/*}*/

.container .board .msg-iconfont div:hover::after{
  position: absolute;
  /*background-color: #ccc3c3; !* 提示文字背景颜色 *!*/
  background-color: #333; /* 提示文字背景颜色 */
  color: #fff; /* 提示文字颜色 */
  padding: 5px; /* 提示文字内边距 */
  border-radius: 5px; /* 提示文字边框圆角 */
  bottom: -25px; /* 提示文字相对于父元素的位置 */
  /*transform: translateX(-50%); !* 提示文字水平居中 *!*/
  white-space: nowrap; /* 不换行 */
  z-index: 999; /* 提示文字位于元素之上 */
  font-size: 12px;
}

.container .board .msg-iconfont div:first-of-type:hover::after{
  content: "댓글"; /* 提示文字内容 */
  left: 0; /* 提示文字相对于父元素的位置 */
}

.container .board .msg-iconfont div:nth-of-type(2):hover::after{
  content: "복사"; /* 提示文字内容 */
  left: 30px; /* 提示文字相对于父元素的位置 */
}

.container .board .msg-iconfont div:nth-of-type(3):hover::after{
  content: "취소하기"; /* 提示文字内容 */
  right: 0; /* 提示文字相对于父元素的位置 */
}

.container .board .msg-iconfont .msg-withdraw{
  display: none;
}

.container .board .msg-align span {
  font-size: 12px;
  color: var(--grey);
}

.container .board .bubble {
  font-size: 15px;
  line-height: 18px;
  max-width: 325px;
  position: relative;
  display: inline-block;
  clear: both;
  padding: 13px 14px;
  vertical-align: top;
  border-radius: 5px;
  margin-bottom: 4px;
  word-break: break-all;
}

.container .board .bubble a.consult, .yydis a.consult {
  display: block;
  font-size: 15px;
  line-height: 21px;
  word-break: break-all;
  color: var(--dark);
}

.container .board .bubble strong.consult, .container .board .bubble strong.basic-msg , .yydis strong.consult{
  display: block;
  font-size: 15px;
  line-height: 21px;
  word-break: break-all;
}

.container .board .bubble-img {
  max-width: 180px;
  position: relative;
  display: inline-block;
  clear: both;
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 4px;
}

.container .board .bubble-img a {
  display: block;
  width: 100%;
}

.container .board .bubble-img a:not(:first-child) {
  margin-top: 6px;
}

.container .board .bubble-img img {
  width: 100%;
}

.container .board .bubble-file {
  position: relative;
  width: 200px;
  display: inline-block;
  clear: both;
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 4px;
}

.container .board .bubble-file a {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.container .board .bubble-file img {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}

.container .board .bubble-file a {
  text-decoration: none;
}

.container .board .bubble-file em, .yy_des em {
  font-size: 13px;
  word-break: break-all;
  line-height: 16px;
}

.container .board .bubble-file.you em {
  color: var(--dark);
}

.container .board .bubble-file.me em {
  color: var(--dark);
}

.container .board .bubble:before,
.container .board .bubble-img:before,
.container .board .bubble-file:before {
  position: absolute;
  top: 19px;
  display: block;
  width: 8px;
  height: 6px;
  content: '\00a0';
  -webkit-transform: rotate(29deg) skew(-35deg);
  transform: rotate(29deg) skew(-35deg);
}

.container .board .bubble.you,
.container .board .bubble-img.you,
.container .board .bubble-file.you {
  color: var(--dark);
  background-color: #eceff1;
}

.container .board .bubble.you:before,
.container .board .bubble-img.you:before,
.container .board .bubble-file.you:before {
  left: -3px;
  background-color: #eceff1;
}

.container .board .bubble.me,
.container .board .bubble-img.me,
.container .board .bubble-file.me {
  float: right;
  color: var(--dark);
  background-color: #ffebeb;
  align-self: flex-end;
  -webkit-animation-name: slideFromRight;
  animation-name: slideFromRight;
}

.container .board .bubble.me:before,
.container .board .bubble-img.me:before,
.container .board .bubble-file.me:before {
  right: -3px;
  background-color: #ffebeb;
}

.container .board .msg-readers {
  margin-top: 12px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 14px;
  border:2px solid #cccccc;
  border-radius: 9px;
  box-sizing: border-box;
  color: #cccccc;
  font-size: 12px;
  font-weight:bold;
}

.container .board .msg-readers.active {
  color: #7CCD7C;
  border-color: #7CCD7C;
}

.container .board .overview h3 {
  margin-bottom: 12px;
}

.container .board .overview-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.container .board .overview-list a {
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 8px;
  text-decoration: none;
}

.container .board .overview-list a:nth-child(even) {
  margin-left: 15%;
}

.container .board .overview-list span {
  color: var(--dark);
}

.container .board .overview-list strong {
  font-size: 18px;
  color: var(--dark);
  text-decoration: underline;
}

.container .board .msg-product, .yyutis {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  overflow: hidden;
  text-decoration: none;
  color: var(--dark);
}
.container .board .msg-product{
  justify-content: space-between;
}

.container .board .bubble.you .msg-product {
  color: var(--dark);
}

.container .board .bubble.me .msg-product {
  color: var(--dark);
}

.container .board .msg-product img, .yyutis img {
  width: 80px;
  height: 80px;
  margin-right: 8px;
}

.container .board .msg-product-info {
  display: flex;
  width: 184px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
}

.container .board .msg-product-info h4 {
  width: 100%;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 6px;
}

.container .board .msg-product-opt {
  width: 100%;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 12px;
}

.container .board .msg-product-info section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.container .board .msg-product-price {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.container .board .msg-product-price strong {
  font-size: 20px;
}

.container .board .msg-product-price em {
  font-size: 12px;
}

.container .board .msg-product-info span {
  font-size: 14px;
  color: var(--dark);
}

.container .board .bubble.you .msg-product-info span {
  color: var(--dark);
}

.container .board .bubble.me .msg-product-info span {
  color: var(--dark);
}

.container .board .conversation-start {
  position: relative;
  width: 100%;
  margin-bottom: 27px;
  text-align: center;
}

.container .board .conversation-start span {
  font-size: 14px;
  display: inline-block;
  color: var(--grey);
}

.container .board .conversation-start span:before,
.container .board .conversation-start span:after {
  position: absolute;
  top: 10px;
  display: inline-block;
  width: 30%;
  height: 1px;
  content: '';
  background-color: var(--light);
}

.container .board .conversation-start span:before {
  left: 0;
}

.container .board .conversation-start span:after {
  right: 0;
}

.container .board .sending-load {
  position: absolute;
  bottom: 160px;
  left: 50%;
  margin-left: -50px;
}

.container .board .sending-load img {
  width: 100px;
}

.separator-4 {
  height: 4px;
}
.separator-8 {
  height: 8px;
}
.separator-12 {
  height: 12px;
}
.separator-16 {
  height: 16px;
}
.separator-20 {
  height: 20px;
}

.yy{
  display: flex;
  /*flex-direction: column;*/
  /*border-left: solid 4px #898686;*/
  /*background: #dbdbdb8f;*/
  padding: 20px;
  /*margin-right: 50px;*/
  /*margin-bottom: 8px;*/
  /*user-select: none;*/
  margin-top: 5px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.yy_msg{
  width: 97%;
  display: flex;
  flex-direction: column;
}

.yy_img {
  margin-left: 15px;
  cursor: pointer;
}

.yy_img img {
  width: 20px;
}

.yy_name{
  color: #616464;
  font-weight: 600;
  margin-bottom: 5px;
}

.yydis{
  display: flex;
  flex-direction: column;
  border-left: solid 4px #cccccc;
  padding: 20px;
  margin-bottom: 10px;
  /*user-select: none;*/
  cursor: pointer;
}
.yydis_name{
  color: #787888;
}

.yydis_context{
  color: #787888;
}
.yy_div,.yydis_div{
  margin-bottom: 10px;
}
.yy_des{
  display: flex;
  align-items: center;
}
.yy_des img{
  width: 40px;
  height: 40px;
  margin-right: 8px;
}

.yy .msg-align {
  margin: 0 16px 0 1px!important;
}

#goToQuoteButton {
  /*display: none;*/
  position: absolute;
  bottom: 30px;
  right: 30px;
  padding: 12px;
  background-color: #ffffff;
  color: #437fc2;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

#goToQuoteButton img {
  margin-right: 3px;
}

#goToQuoteButton:hover {
  background-color: #ededed; /* 鼠标悬停时的背景色，根据需要调整 */
}

.mask_layer{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #8979796b;
  z-index: 999;
  right: 0;
  bottom: 0;
  border-radius: 10px;
}
.board2{
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #00000021;
}

.auto-reply{display:flex;justify-content:flex-start;align-items:center;padding:8px 8px 0 8px;}
.auto-reply span{padding:4px 8px;font-size:14px;color:#535353;border:1px solid #bfbfbf;border-radius:3px;margin-right:8px;cursor:pointer;}