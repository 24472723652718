.loading-main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loading-main img {
    width: 300px;
}

.loading-main strong {
    font-size: 14px;
    color: #999999;
}