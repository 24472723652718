.container .info {
    position: relative;
    width: 500px;
    height: 100%;
    padding-top: 24px;
    overflow: hidden;
}

.container .info-box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 16px 16px 16px;
    overflow-y: scroll;
}

.container .info-box::-webkit-scrollbar {
    width: 6px;
    height: 1px;
}

.container .info-box::-webkit-scrollbar-thumb {
    border-radius: 6px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #535353;
}

.container .info-box::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background: #ededed;
}

.container .info-card {
    margin-top: 18px;
    background: #ffffff;
    padding: 16px 16px 6px 16px;
    border-radius: 5px;
    box-shadow: 0 0 24px #bfbfbf;
}

.container .info-card:nth-of-type(1) {
    margin-top: 0;
}

.container .info .info-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
}

.container .info .info-title h3 {
    font-size: 18px;
    font-weight: bold;
}

.container .info .info-title button {
    border: 1px solid #bfbfbf;
    color: #1d1d1d;
    font-size: 13px;
    border-radius: 3px;
    padding-right: 16px;
    cursor: pointer;
}

.container .info .info-title button.fold {
    background: url('../../Common/img/down.png') no-repeat right center;
}

.container .info .info-title button.unfold {
    background: url('../../Common/img/up.png') no-repeat right center;
}

.container .info .info-data {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.container .info .info-data section {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin-bottom: 20px;
}

.container .info .info-data strong {
    width: 65px;
    font-size: 13px;
    font-weight: bold;
    color: #a2a2a2;
    margin-right: 10px;
}

.container .info .info-data span {
    font-size: 14px;
    color: #545454;
    margin-right: 10px;
}

.container .info .info-data a {
    font-size: 14px;
    font-weight: bold;
}

.container .info .info-data i {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('../../Common/img/ic1.png') no-repeat center center;
    cursor: pointer;
}

.container .info .info-data-btn {
    font-size: 12px;
    color: #545454;
    border: 1px solid #bfbfbf;
    background: #ffffff;
    cursor: pointer;
}

.container .info .info-btn-group {
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.container .info .info-btn-group button {
    font-size: 12px;
    color: #545454;
    border: 1px solid #bfbfbf;
    background: #ffffff;
    cursor: pointer;
    margin-right: 8px;
}

.container .info .info-item {
    position: relative;
    margin-bottom: 16px;
}

.container .info .info-item-data {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-decoration: none;
}

.container .info .info-item-data img {
    width: 90px;
    height: 90px;
    margin-right: 8px;
}

.container .info .info-item-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
}

.container .info .info-item-content section {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    color: #40BD1A;
    margin-bottom: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.container .info .info-item-content h4 {
    width: 100%;
    font-size: 15px;
    font-weight: bold;
    color: #202020;
    margin-bottom: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.container .info .info-item-opt {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 10px;
    font-size: 12px;
    color: #a2a2a2;
}

.container .info .info-item-num {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: bold;
    color: #7b53ef;
}

.container .info .item-btn-group {
    position: absolute;
    right: 0;
    bottom: -2px;
    z-index: 99;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.container .info .item-btn-group button {
    color: #1d1d1d;
    font-size: 12px;
    padding: 4px 8px;
    border: 1px solid #bfbfbf;
    border-radius: 6px;
    background: none;
    cursor: pointer;
    margin-left: 8px;
}

.container .info .info-btn {
    text-align: center;
    padding-bottom: 10px;
}

.container .info .info-btn button {
    border: 1px solid #bfbfbf;
    color: #1d1d1d;
    font-size: 14px;
    font-weight: bold;
    border-radius: 10px;
    padding: 8px 8px 8px 36px;
    cursor: pointer;
    background: url('../../Common/img/ic2.png') no-repeat 12px center;
}

.container .leaving-n {
    margin-top: 12px;
    background: #ffffff;
    padding: 12px;
    border-radius: 5px;
    word-break: break-all;
}

.container .leaving-n:nth-of-type(1) {
    margin-top: 0;
}

.container .leaving-n .n-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 16px;
    line-height: 18px;
}

.container .leaving-n .n-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.container .leaving-n .n-list a {
    margin-bottom: 12px;
    padding-bottom: 12px;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-decoration: none;
}

.container .leaving-n .n-list strong {
    margin-bottom:12px;
    color: #f46212;
    font-size: 14px;
    line-height: 18px;
}

.container .leaving-n .n-list span {
    color: #4938f4;
    font-size: 14px;
    line-height: 18px;
}

.container .app-download {
    background: #ffffff;
    padding: 12px;
    border-radius: 5px;
    word-break: break-all;
}

.container .app-download .app-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 16px;
    line-height: 18px;
}

.container .app-download .app-title strong {
    color: #4938f4;
}

.container .app-download .app-qrcode {
    text-align: center;
}

.container .app-download .app-qrcode img {
    width: 90px;
}

.container .utis-product {
    margin: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background: #ffffff;
    padding: 12px;
    box-shadow: 0 0 24px #bfbfbf;
    border-radius: 5px;
    overflow: hidden;
    box-sizing: border-box;
}
.container .utis-product img {
    width: 18%;
    border-radius:5px;
    margin-right: 2%;
}
.container .utis-product section {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.container .utis-product h3 {
    width: 100%;
    font-size: 14px;
    line-height:18px;
    color: #202020;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 3px;
}
.container .utis-product em {
    font-size: 12px;
    color: #a2a2a2;
    margin-bottom: 6px;
}
.container .utis-product p {
    width: 100%;
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.container .utis-product strong {
    font-size: 13px;
    color: #202020;
}
.container .utis-product button {
    width: 60px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    text-decoration: none;
    background: rgb(255, 96, 0);
    border-radius: 12px;
    color: #ffffff;
    font-size: 12px;
    border: none;
    cursor: pointer;
}