.context-menu {
    display: none;
    position: absolute;
    left: 500px;
    top: 300px;
    background: #ffffff;
    border: 1px solid #e2e2e2;
    width: 140px;
    z-index: 99999999;
    border-radius: 5px;
    -moz-user-select:none;/*火狐*/
    -webkit-user-select:none;/*webkit浏览器*/
    -ms-user-select:none;/*IE10*/
    -khtml-user-select:none;/*早期浏览器*/
    user-select:none;
}
.context-menu li {
    padding: 10px 16px;
    display: flex;
    justify-content: flex-start;
    align-items:center;
    cursor: pointer;
}
.context-menu li:hover {
    background: #eeeeee;
}
.context-menu img {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}
.context-menu span {
    font-size: 14px;
    color: #545454;
}